let hamburger = document.querySelector('.hamburger-button')
let hamburgerPopup = document.querySelector('.hamburger-popup')
let headerBg = document.querySelector('header .bg')

// BACKGROUND PARRALAXE

if (headerBg) {
    document.addEventListener('scroll', () => {
        headerBg.style.backgroundPosition = `center ${(50 + window.scrollY * 0.05)}%`
    })
}

// LEFT SIDE PARRALAXE

// let bg = document.querySelector('.left')

// document.addEventListener('mousemove', (e) => {
//     posX = e.x - (window.innerWidth/2)
//     posY = e.y - (window.innerHeight/2)
//     bg.style.backgroundPosition = `${50 + (posX*0.05)}% ${50 + (posY*0.05)}%`
// })

// OPEN HAMBURGER 

hamburger.addEventListener('click', () => {
    let lines = hamburger.querySelectorAll('.hamburger-line')
    for (const line of lines) {
        if(!line.classList.contains('open')){
            if (line.classList.contains('closed')) {
                line.classList.replace('closed', 'open')
                hamburgerPopup.classList.replace('closed', 'open')
            }
            line.classList.add('open')
            hamburgerPopup.classList.add('open')
        } else {
            line.classList.replace('open', 'closed')
            hamburgerPopup.classList.replace('open', 'closed')
        }
    }
})

// General JS

let windowWidth = window.innerWidth
let windowHeight = window.innerHeight


// ANIMATE EVERYTHING WITH SLIDEIN

let animatedThings = document.querySelectorAll('.sft, .sfb, .sfl')

function slideInAll(thing) {
    if (window.scrollY > thing.offsetTop - (windowHeight)) {
        thing.classList.add('animated')   
    }
}

for (const thing of animatedThings) {
    slideInAll(thing)
    document.addEventListener('scroll', () => {
        slideInAll(thing)
    })
}

// LIST ANIMATION

let lists = document.querySelectorAll('.animated-list')

function slideInLists(list, listItems) {
    if (window.scrollY > list.offsetTop - (windowHeight)) {
        for (let i = 0; i < listItems.length; i++) {
            setTimeout(function () {
                listItems[i].classList.add('animate')
            }, 200 * i); // Le temps augmentera de 200 ms à chaque élément
        }
    }
}

for (const list of lists) {
    let listItems = list.querySelectorAll('.animated-item, li')
    slideInLists(list, listItems)
    document.addEventListener('scroll', () => {
        slideInLists(list, listItems)
    })
}


// PAGE LEAVE ANIMATION

// let links = document.querySelectorAll('.src')

// for (const button of links) {
//     button.addEventListener('click', () => {
//         changePageTimeout(button.dataset.src, button.dataset.timeout, button.dataset.target)
//     })
// }

// // CHANGE PAGE

// function changePageTimeout(page, timeout, target){
//     if (target == 'blank') {
//         setTimeout(() => {window.open(`${page}`), timeout})
//     }
//     else {
//         setTimeout(() => {window.location = `${page}`}, timeout)
//     }
        
// }

function DeselAndSel(elements) {
    for (const element of elements) {
        element.addEventListener('click', () => {
            for (const element of elements) {
                element.classList.remove('active')
            }
            element.classList.add('active')
        })
    }
}

// BUTTON QUESTIONNAIRE ACTIVED

let buttons = document.querySelectorAll('.button-round')
DeselAndSel(buttons)


// LABEL WITH RADIO INPUT IN RECTANGLE QUESTIONNAIRE ACTIVATED

let labels = document.querySelectorAll('.rectangle > label')
DeselAndSel(labels)

// FOCUS ON FORM INPUTS

let supportForm = document.querySelector('.support-form')
if (supportForm) {
    let inputs = supportForm.querySelectorAll('.input')
    focusInput(inputs)
}
function focusInput(inputs) {
    for (const inputCont of inputs) {
        let input = inputCont.querySelector('input')
        input.addEventListener('focus', () => {
            let label = inputCont.querySelector('label')
            label.classList.add('active')
        })
        input.addEventListener('blur', () => {
            let label = inputCont.querySelector('label')
            if(input.value.length == 0){
                label.classList.remove('active')
            }
        })
    }
}

// FILTRE PAGE CATALOGUE

let filtres = document.querySelectorAll('.filtre')

function filtreElement(button, type) {
    console.log(type);
    let cadres = document.querySelectorAll('.cadre')
    for (const filtre of filtres) {
        filtre.classList.remove('active')
    }   
    button.classList.add('active')
    for (const cadre of cadres) {
        cadre.classList.remove('d-none')
    }
    if (type != "tous") {
        for (const cadre of cadres) {
            if (cadre.dataset.type != type) {
                cadre.classList.add('d-none')
            }
        }
    }
}

for (const filtre of filtres) {
    let filtreType = filtre.dataset.filtre
    filtre.addEventListener('click', () => {filtreElement(filtre, filtreType)})
}

// INPUT NUMBER

// let inputPoids = document.querySelector('.poids')
// console.log(inputPoids.value);


// inputPoids.addEventListener('keypress', (e) =>  {
//     e.preventDefault
//     console.log(inputPoids.value);
//     if (inputPoids.value > 1) {
//         inputPoids.value += "\."
//     }
// })

